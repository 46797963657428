import {
    TypographyColorName,
    TypographyColorNumber,
    TypographyStyle,
    TypographyTypes
} from './typography.model';

export const getTypographyDesktopStyleMap = () =>
    new Map<keyof typeof TypographyTypes, TypographyStyle>([
        [
            TypographyTypes.TINY,
            {
                fontSize: '11px',
                fontWeight: '400',
                letterSpacing: '0',
                lineHeight: '12.7px'
            }
        ],
        [
            TypographyTypes.SMALL,
            {
                fontSize: '13px',
                fontWeight: '400',
                letterSpacing: '0',
                lineHeight: '15px'
            }
        ],
        [
            TypographyTypes.SMALL_BOLD,
            {
                fontSize: '13px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '15px'
            }
        ],
        [
            TypographyTypes.SECONDARY,
            {
                fontSize: '18px',
                letterSpacing: '0',
                fontWeight: '400',
                lineHeight: '21px'
            }
        ],
        [
            TypographyTypes.SECONDARY_BOLD,
            {
                fontSize: '18px',
                letterSpacing: '0',
                lineHeight: '21px',
                fontWeight: '700'
            }
        ],
        [
            TypographyTypes.PLACEHOLDER,
            {
                fontSize: '18px',
                letterSpacing: '0',
                lineHeight: '21px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.PRIMARY,
            {
                fontSize: '20px',
                letterSpacing: '0',
                lineHeight: '23px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.PRIMARY_BOLD,
            {
                fontSize: '20px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '23px'
            }
        ],
        [
            TypographyTypes.HEADLINE,
            {
                fontSize: '28px',
                letterSpacing: '0',
                lineHeight: '32.2px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.HEADLINE_BOLD,
            {
                fontSize: '28px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '32.2px'
            }
        ],
        [
            TypographyTypes.SUBTITLE,
            {
                fontSize: '38px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '43.7px'
            }
        ],
        [
            TypographyTypes.TITLE,
            {
                fontSize: '48px',
                letterSpacing: '0',
                lineHeight: '55.2px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.TITLE_BOLD,
            {
                fontSize: '48px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '55.2px'
            }
        ]
    ]);

export const getTypographyMobileStyleMap = () =>
    new Map<keyof typeof TypographyTypes, TypographyStyle>([
        [
            TypographyTypes.TINY,
            {
                fontSize: '11px',
                fontWeight: '400',
                letterSpacing: '0',
                lineHeight: '13px'
            }
        ],
        [
            TypographyTypes.SMALL,
            {
                fontSize: '12px',
                fontWeight: '400',
                letterSpacing: '0',
                lineHeight: '14px'
            }
        ],
        [
            TypographyTypes.SMALL_BOLD,
            {
                fontSize: '12px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '14px'
            }
        ],
        [
            TypographyTypes.SECONDARY,
            {
                fontSize: '15px',
                letterSpacing: '0',
                lineHeight: '17.2px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.SECONDARY_BOLD,
            {
                fontSize: '15px',
                letterSpacing: '0',
                lineHeight: '17.2px',
                fontWeight: '700'
            }
        ],
        [
            TypographyTypes.PLACEHOLDER,
            {
                fontSize: '15px',
                letterSpacing: '0',
                lineHeight: '17.2px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.PRIMARY,
            {
                fontSize: '16px',
                letterSpacing: '0',
                lineHeight: '18.4px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.PRIMARY_BOLD,
            {
                fontSize: '16px',
                fontWeight: 'bold',
                letterSpacing: '0',
                lineHeight: '18.4px'
            }
        ],
        [
            TypographyTypes.HEADLINE,
            {
                fontSize: '19.5px',
                letterSpacing: '0',
                lineHeight: '22.5px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.HEADLINE_BOLD,
            {
                fontSize: '19.5px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '22.5px'
            }
        ],
        [
            TypographyTypes.SUBTITLE,
            {
                fontSize: '28px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '32.2px'
            }
        ],
        [
            TypographyTypes.TITLE,
            {
                fontSize: '40px',
                letterSpacing: '0',
                lineHeight: '46px',
                fontWeight: '400'
            }
        ],
        [
            TypographyTypes.TITLE_BOLD,
            {
                fontSize: '40px',
                fontWeight: '700',
                letterSpacing: '0',
                lineHeight: '46px'
            }
        ]
    ]);

export const getTypographyColorMap = () =>
    new Map<keyof typeof TypographyColorName, TypographyColorNumber>([
        [TypographyColorName.DEFAULT_COLOR, { color: '#403c3e' }],
        [TypographyColorName.DEFAULT_COLOR_DISABLED, { color: '#D7D7D7' }],
        [TypographyColorName.WARN, { color: '#f44336' }],
        [TypographyColorName.TURQUOISE, { color: '#216266' }],
        [TypographyColorName.WHITE, { color: '#fff' }],
        [TypographyColorName.BLACK, { color: '#000' }],
        [TypographyColorName.LIGHT_GRAY, { color: '#0000008A' }],
        [TypographyColorName.ACCENT, { color: '#ffc905' }],
        [TypographyColorName.BLUE_SKY, { color: '#4a90e2' }],
        [TypographyColorName.LIGHT_LIGHT_GRAY, { color: '#848484' }],
        [TypographyColorName.GREEN, { color: '#43a047' }],
        [TypographyColorName.RED, { color: '#bf360c' }]
    ]);

export const getOpacityTypographyColorMap = () =>
    new Map<keyof typeof TypographyColorName, TypographyColorNumber>([
        [TypographyColorName.DEFAULT_COLOR, { color: 'rgba(64,60,62,0.5)' }],
        [
            TypographyColorName.DEFAULT_COLOR_DISABLED,
            { color: 'rgba(215,215,215,0.5)' }
        ],
        [TypographyColorName.WARN, { color: 'rgba(244,67,54,0.5)' }],
        [TypographyColorName.TURQUOISE, { color: 'rgba(33,98,102,0.5)' }],
        [TypographyColorName.WHITE, { color: 'rgba(255,255,255,0.5)' }],
        [TypographyColorName.BLACK, { color: 'rgba(0,0,0,0.5)' }],
        [TypographyColorName.LIGHT_GRAY, { color: 'rgba(0,0,0,0.5)' }],
        [TypographyColorName.ACCENT, { color: 'rgba(255,201,5,0.5)' }],
        [TypographyColorName.BLUE_SKY, { color: 'rgba(74,144,226,0.5)' }],
        [
            TypographyColorName.LIGHT_LIGHT_GRAY,
            { color: 'rgba(132,132,132,0.5)' }
        ],
        [TypographyColorName.GREEN, { color: 'rgba(67,160,71,0.5)' }],
        [TypographyColorName.RED, { color: 'rgba(191,54,12,0.5)' }]
    ]);
