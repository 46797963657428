import {
    Directive,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { NgStyle } from '@angular/common';
import { TypographyColorName, TypographyTypes } from './typography.model';
import {
    getOpacityTypographyColorMap,
    getTypographyColorMap,
    getTypographyDesktopStyleMap,
    getTypographyMobileStyleMap
} from './typography.const';

@Directive({
    selector: '[appTypography]',
    standalone: true,
    hostDirectives: [NgStyle]
})
export class TypographyDirective implements OnInit, OnChanges {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appTypography') type: keyof typeof TypographyTypes | undefined =
        TypographyTypes.SECONDARY;
    @Input() color: keyof typeof TypographyColorName =
        TypographyColorName.DEFAULT_COLOR;

    @Input() additionStyle?: { [klass: string]: any };
    ngStyleDirective = inject(NgStyle);

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type?.firstChange || changes.color?.firstChange) {
            return;
        }
        this.initStyle();
    }

    ngOnInit() {
        this.initStyle();
    }

    initStyle() {
        const isMobile = window.innerWidth <= 600;
        this.ngStyleDirective.ngStyle = {
            ...(isMobile
                ? getTypographyMobileStyleMap().get(this.type!)
                : getTypographyDesktopStyleMap().get(this.type!)),
            ...(this.type === 'PLACEHOLDER'
                ? getOpacityTypographyColorMap().get(this.color)
                : getTypographyColorMap().get(this.color)),
            ...(this.additionStyle || {})
        };
        this.ngStyleDirective.ngDoCheck();
    }
}
