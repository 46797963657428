export enum TypographyTypes {
    TITLE = 'TITLE',
    TITLE_BOLD = 'TITLE_BOLD',
    SUBTITLE = 'SUBTITLE',
    HEADLINE = 'HEADLINE',
    HEADLINE_BOLD = 'HEADLINE_BOLD',
    PRIMARY = 'PRIMARY',
    PRIMARY_BOLD = 'PRIMARY_BOLD',
    PLACEHOLDER = 'PLACEHOLDER',
    SECONDARY = 'SECONDARY',
    SECONDARY_BOLD = 'SECONDARY_BOLD',
    SMALL = 'SMALL',
    SMALL_BOLD = 'SMALL_BOLD',
    TINY = 'TINY'
}

export interface TypographyStyle {
    fontSize: string;
    fontWeight: string;
    letterSpacing: string;
    lineHeight: string;
}

export enum TypographyColorName {
    DEFAULT_COLOR = 'DEFAULT_COLOR',
    DEFAULT_COLOR_DISABLED = 'DEFAULT_COLOR_DISABLED',
    WARN = 'WARN',
    TURQUOISE = 'TURQUOISE',
    WHITE = 'WHITE',
    BLACK = 'BLACK',
    LIGHT_GRAY = 'LIGHT_GRAY',
    ACCENT = 'ACCENT',
    BLUE_SKY = 'BLUE_SKY',
    LIGHT_LIGHT_GRAY = 'LIGHT_LIGHT_GRAY',
    GREEN = 'GREEN',
    RED = 'RED'
}

export interface TypographyColorNumber {
    color: string;
}
